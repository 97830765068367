import BigNumber from "bignumber.js";

export const BN_ZERO = new BigNumber(0);
export const BN_ONE = new BigNumber(1);

export const Paths = {
  Landing: {
    Index: "/",
  },
};

export const DEFAULT_URL = "";

export const PUSH_NOTIFICATION_STORAGE_KEY = "push-notification-register-status";
export const REGISTERED = "REGISTERED";

export const ENTER_SITE_URL = "https://a.vtrk8.com/58c39ce7-66eb-4ac7-9355-9b4dc7c39a40";

export const ENTER_SITE_URL_MAP = {
  "kbk77.vip": "https://peneciesmantable.com/58c39ce7-66eb-4ac7-9355-9b4dc7c39a40",
};

export const FLOATING_BANNER_URL = "https://kbk9thai.com/cs";

export * from "./codes";
export * from "./context";
