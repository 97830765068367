import { VisuallyHidden } from "@99tech/ui";
import { Box, Stack } from "@mui/material";
import React from "react";
import { EnterSite, HeroChars, HeroCoins, HeroDragon, HeroRoulette, HeroSlotreels, Logo } from "src/assets";
import { FloatingBanner } from "src/main/components";
import { createStyles } from "src/main/utils";

interface LandingPageProps extends React.PropsWithChildren {}

const LandingPage: React.FC<LandingPageProps> = (props) => {
  return (
    <Box sx={styles.root}>
      <VisuallyHidden as="h1">KBK99</VisuallyHidden>
      <Stack
        alignItems="center"
        justifyContent="center"
        spacing={1}
      >
        <img
          alt="logo"
          src={Logo}
          style={{ paddingLeft: 15 }}
          height={55}
        />
        <img
          alt="enter site"
          src={EnterSite}
          style={{ width: 210 }}
        />
      </Stack>

      <Box sx={styles.images}>
        <img
          alt="hero coins"
          src={HeroCoins}
          style={{
            animation: "herocoins 1.2s ease-out 0.6s infinite, fadeCoins 0.6s linear",
            left: 10,
            top: 225,
          }}
        />

        <img
          alt="hero dragon"
          src={HeroDragon}
          style={{
            animation: "herodragon 1.2s ease-out 0.6s infinite, fadeDragon 0.6s linear",
            left: 58,
            top: 30,
          }}
        />

        <img
          alt="hero slotreels"
          src={HeroSlotreels}
          style={{
            width: 140,
            left: 125,
            top: 125,
          }}
        />

        <img
          alt="hero chars"
          src={HeroChars}
          style={{
            animation: "herochars 1.2s ease-out 0.6s infinite, fadeChars 0.6s linear",
            left: 0,
            right: 0,
            margin: "0 auto",
            top: 114,
          }}
        />

        <img
          alt="hero roulette"
          src={HeroRoulette}
          style={{
            left: 2,
            top: 252,
          }}
        />
      </Box>

      <FloatingBanner />
    </Box>
  );
};

const styles = createStyles({
  root: {
    background: `radial-gradient(50% 25% at 50% 0%, #EB81FF 0%, rgba(55, 4, 81, 0.00) 100%, rgba(0, 0, 0, 0.00) 100.01%)`,
    pt: "20px",
    pb: "20px",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    gap: "0px",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    overflowX: "hidden",
  },
  images: {
    zIndex: 10,
    position: "relative",
    width: "360px",
    height: "400px",
    img: {
      position: "absolute",
    },
  },
  footer: {
    zIndex: 0,
    position: "fixed",
    bottom: "0",
    width: "100vw",
    height: "200px",
    flexShrink: 0,
  },
});

export default LandingPage;
