import { getRedirectUrl } from "@99tech/shared-utils";
import { Box, Container, ContainerProps } from "@mui/material";
import React, { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { Bg } from "src/assets";
import { ENTER_SITE_URL, ENTER_SITE_URL_MAP, RefCodeMap } from "src/main/constants";
import { useFbcid } from "src/main/hooks";
import { createStyles } from "src/main/utils";

interface ContentProps extends React.PropsWithChildren, ContainerProps {}

const Content: React.FC<ContentProps> = (props) => {
  const { children, ...containerProps } = props;
  const { fbcid } = useFbcid();
  const [params] = useSearchParams();

  const handleClickPage = useCallback(() => {
    let hostname = window.location.hostname;
    if (hostname.startsWith("www.")) {
      hostname = hostname.replace("www.", "");
    }
    const url = getRedirectUrl(ENTER_SITE_URL_MAP[hostname] ?? ENTER_SITE_URL, fbcid, params, RefCodeMap);
    window.open(url, "_blank");
  }, [fbcid, params]);

  return (
    <Box
      sx={styles.root}
      onClick={handleClickPage}
    >
      <Container
        {...containerProps}
        sx={styles.container}
      >
        {children}
      </Container>
    </Box>
  );
};

const styles = createStyles({
  root: {
    backgroundImage: `url(${Bg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundColor: "black",
    minHeight: "100vh",
    cursor: "pointer",
  },
  container: {
    height: "100%",
    px: 0,
  },
});

export default Content;
