import { Box, BoxProps, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  GetAppButton,
  GetAppTitle,
  HelpGifOpenExtBrowser,
  HelpGifOpenExtBrowserMp4,
  HelpGifOpenShareAddHomescreen,
  HelpGifOpenShareAddHomescreenMp4,
} from "src/assets";
import { useAppContext } from "src/main/constants";
import { useGtmTracker } from "src/main/hooks";
import { EvtAction } from "src/main/hooks/useGtmTracker";
import { createStyles } from "src/main/utils";
import HelpGifDialog from "../HelpGifDialog";

interface BeforeInstallEvent {
  prompt: () => void;
  userChoice: Promise<any> | null;
}

interface InstallBannerProps extends BoxProps {}

const InstallBanner: React.FC<InstallBannerProps> = (props) => {
  const { ...rest } = props;
  const { sendEvent, sendSingularEvent } = useGtmTracker();
  const { isFbIab = false, isLineIab = false, isIosDevice = false, isFullScreen = false } = useAppContext();
  const [requestedInstall, setRequestedInstall] = useState<boolean>(false);
  const [deferredPrompt, setDeferredPrompt] = useState<BeforeInstallEvent | null | undefined>();

  useEffect(() => {
    const handler = (e: Event) => {
      e.preventDefault();
      setDeferredPrompt(e as unknown as BeforeInstallEvent);
    };
    window.addEventListener("beforeinstallprompt", handler);
    return () => window.removeEventListener("beforeinstallprompt", handler);
  }, [setDeferredPrompt]);

  useEffect(() => {
    if (requestedInstall && (isFbIab || isLineIab)) {
      sendSingularEvent(EvtAction.ShowOpenExtBrowser);
    }
  }, [sendSingularEvent, requestedInstall, isFbIab, isLineIab]);

  useEffect(() => {
    if (requestedInstall && !isFbIab && !isLineIab && isIosDevice) {
      sendSingularEvent(EvtAction.ShowIosAddToHome);
    }
  }, [sendSingularEvent, requestedInstall, isFbIab, isLineIab, isIosDevice]);

  const canInstall = deferredPrompt !== null && deferredPrompt !== undefined;

  if (!canInstall && !isFbIab && !isLineIab && !isIosDevice) return null;
  if (isFullScreen) return null;

  const install = () => {
    (async () => {
      deferredPrompt?.prompt();
      const { outcome } = await deferredPrompt?.userChoice;
      setDeferredPrompt(null);
      if (outcome === "accepted") {
        // success
        sendEvent(EvtAction.InstallSuccess);
      } else if (outcome === "dismissed") {
        // dismissed
      }
    })();
  };

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation?.();
    e.preventDefault?.();
    sendEvent(EvtAction.ClickInstallBanner);
    if (isFbIab || isLineIab || isIosDevice) {
      setRequestedInstall(true);
    } else {
      install();
    }
  };

  const handleClose = (e) => {
    e.stopPropagation?.();
    e.preventDefault?.();
    setRequestedInstall(false);
  };

  return (
    <Box
      sx={styles.root}
      {...rest}
    >
      <Box sx={styles.container}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
          paddingX={"8px"}
          height={"56px"}
          onClick={handleClick}
        >
          <img
            src={GetAppTitle}
            alt="get app"
            height={52}
          />
          <img
            src={GetAppButton}
            alt="get app button"
            height={64}
          />
        </Stack>
        <Box
          height={"4px"}
          width={"100%"}
          sx={{
            position: "absolute",
            bottom: 0,
            background: `linear-gradient(90deg, #FF9AFF 0%, #9B00FC 17.5%, #8E004A 46.5%, #F0F 67%, #4E00FF 100%)`,
          }}
        ></Box>
      </Box>
      <HelpGifDialog
        title="ดาวน์โหลด KBK99.com!"
        src={HelpGifOpenExtBrowser}
        fallbackSrc={HelpGifOpenExtBrowserMp4}
        open={requestedInstall && isLineIab}
        onClose={(event) => handleClose(event)}
      />
      <HelpGifDialog
        title="ดาวน์โหลด KBK99.com!"
        src={HelpGifOpenExtBrowser}
        fallbackSrc={HelpGifOpenExtBrowserMp4}
        open={requestedInstall && isFbIab}
        onClose={(event) => handleClose(event)}
      />
      <HelpGifDialog
        title="ดาวน์โหลด KBK99.com!"
        src={HelpGifOpenShareAddHomescreen}
        fallbackSrc={HelpGifOpenShareAddHomescreenMp4}
        open={requestedInstall && !isFbIab && !isLineIab && isIosDevice}
        onClose={(event) => handleClose(event)}
      />
    </Box>
  );
};

const styles = createStyles({
  root: {
    position: "sticky",
    top: 0,
    zIndex: 100,
  },
  container: {
    background: `linear-gradient(0deg, #FFF 0%, #FFF 0.01%, #D2CCBD 8.5%, #FFF 100%)`,
    boxShadow: `0px 2px 4px 0px rgba(0, 0, 0, 0.25)`,
    height: "60px",

    width: "100%",
    zIndex: 15,
  },
});

export default InstallBanner;
